import React from "react";
import './style.css';

const Contact = () => {
    return (
        <div>
            <h1 className="style-1">Contact Us!</h1>
        </div>
    );
}
  
export default Contact;